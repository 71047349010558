// Module 2 (Discover) page: looks up the issue to see if it has occurred before and gets a few ideas of how to solve and where the considerations are.
import React, { useState } from "react";
import "./discover.css";
import IssueArea from "./Issue Table/IssueArea";
import issueInfo from "../../datasets/data_cleaning/Discover Module Data/discover_v1.json";
import SpecificationArea from "./Key Specifications/SpecificationArea";

const Discover = () => {
  const [specData, setSpecData] = useState(null);

  return (
    <div className="discover">
      <IssueArea 
        issueInfo={issueInfo} 
        setSpecData={setSpecData} 
        isSharedView={false} 
      />
      <SpecificationArea specData={specData} />
    </div>
  );
};

export default Discover;
