import React, { useEffect } from "react";
import { useAppContext } from "../../../AppContext";
import "./keySpecificationLayout.css";
import { SpeciDataCard, AccordionItems } from "../../../Components/index";

// 1. specificationsMenu: Issue Insights or Key Specs
// 2. selectIssueDetails: current issue (issue ID) and the Related Issues
// 3. subKeySpecsMenu: Design Docs, Standards and the Scholar Search
const SpecificantionsFormat = ({ specificationsMenu, subKeySpecsMenu, selectIssueDetails, specData }) => {
  const { clickedIssue, openItem, setOpenItem, openDataCard, setOpenDataCard} = useAppContext();
  
  //console.log(specData);

  useEffect(() => {
    if (subKeySpecsMenu === "design_docs" && specData[selectIssueDetails]) {
      const firstKey = Object.keys(specData[selectIssueDetails][subKeySpecsMenu])[0];
      //console.log(firstKey);
      setOpenItem([firstKey]); // get the first accordiopn Item Open
    }
  }, 
  // eslint-disable-next-line
  [clickedIssue]);

  // ==> this one looks like will using for the "Scholar Search" in the future
  // const parseKeywords = (keywords) => {
  //   if (typeof keywords === "string") {
  //     // Remove the single quotes and square brackets
  //     const cleanedString = keywords.replace(/^\[|\]$/g, "").replace(/'/g, '"');
  //     try {
  //       return JSON.parse(`[${cleanedString}]`);
  //     } catch (error) {
  //       console.error("Error parsing keywords:", error);
  //       return keywords.split(", ");
  //     }
  //   }
  //   return keywords;
  // };

  // based on user select the item ==> get the display key specification data
  const filteredSpecs = specData[selectIssueDetails]?.[subKeySpecsMenu] || {};
  //console.log(filteredSpecs);

  return (
    <>
      {specificationsMenu === "Key Specs" && <div className="specificationsFormat">
        {Object.entries(filteredSpecs).map(([key, value], index) => (
          <AccordionItems
            key={`${key}-${index}`}
            title={key} // use the key value as the accorditionItem tittle
            defaultWidth="calc(32vw - 74px)"
            openItem={openItem} // memorize which accordionItem user open it
            setOpenItem={setOpenItem} // memorize function to save which accordionItem user open it
          >
            {Array.isArray(value) ? value.map((data) => (
              <SpeciDataCard
                key={`${data.id} ${data.title}`} // create the unine key for each data card
                title={`${data.id} ${data.title}`} // use the data id and tittle as the UI page dispaly tittle area
                sourceLinks={data.sourceLinks}
                defaultWidth="calc(32vw - 105px)"
                context={data.summary} // display the summary
                openDataCard={openDataCard}
                setOpenDataCard={setOpenDataCard}
              />
            )) : []}
          </AccordionItems>
        ))}

      </div >}
    </>
  );
};

export { SpecificantionsFormat };
