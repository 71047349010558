import React, { useState } from "react";
import PropTypes from "prop-types";
import "./Textarea.css";
import Badge from "../Badge/Badge"; // import the Badge component

const Textarea = ({
  label,
  placeholder,
  hint,
  errorMessage,
  type = "generalWithBlack",
  width,
  height,
  isBadgeInput,
  value,
  onChange,
  onKeyDown,
}) => {
  const [inputValue, setInputValue] = useState(value || ""); // if have value, used value first
  const [badges, setBadges] = useState([]);

  // Handle value change
  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue); // update the input value
    if (onChange) {
      onChange(newValue); // tell the the high levle component
    }
  };

  // Handle badge input
  const handleKeyPress = (e) => {
    if (e.key === "Enter" && inputValue.trim()) {
      setBadges([...badges, inputValue.trim()]);
      setInputValue("");
      e.preventDefault(); // avoid the default action
    }
  };

  const handleRemoveBadge = (indexToRemove) => {
    setBadges(badges.filter((_, index) => index !== indexToRemove));
  };

  return (
    <div className={`textarea-container ${type}`}>
      {label && <label className="textarea-label">
        {label}{" "}
        <span
          style={{
            color:
              type === "errorWithBlack" || type === "activeErrorFocus"
                ? "var(--danger-600)"
                : "var(--brand-secondary-400",
          }}
        >
          *
        </span>{" "}
      </label>}
      <div className={`textareaField`} style={{ width, height }}>
        {/* General input field */}
        {!isBadgeInput ? (
          <textarea
            placeholder={placeholder}
            value={value !== undefined ? value : inputValue} // the default value
            onChange={handleInputChange}
            onKeyDown={onKeyDown}
            onClick={(e) => {
              e.stopPropagation();
            }}
            style={{
              width: "100%",
              height: "100%",
              border: "none",
              outline: "none",
              background: "transparent",
              color: "inherit",
              resize: "none",
            }}
            disabled={type === "disabled" ? true : false}
          />
        ) : (
          <div className="badge-input-area">
            {/* Badge input */}
            {badges.map((badge, index) => (
              <Badge
                key={index}
                type="inputWhite"
                onClick={() => handleRemoveBadge(index)}
              >
                {badge}
              </Badge>
            ))}
            {/* Input new badges */}
            <textarea
              placeholder={placeholder}
              value={inputValue}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              disabled={type === "disabled" ? true : false}
            />
          </div>
        )}
      </div>

      <p className={`input-hint`}>{errorMessage ? errorMessage : hint}</p>
    </div>
  );
};

Textarea.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  hint: PropTypes.string,
  errorMessage: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  type: PropTypes.oneOf([
    "generalWithBlack",
    "activeFocus",
    "disabled",
    "errorWithBlack",
    "activeErrorFocus",
  ]),
  isBadgeInput: PropTypes.bool,
  value: PropTypes.string, // option
  onChange: PropTypes.func, // option
  onKeyDown: PropTypes.func,
};

export default Textarea;

