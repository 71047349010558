import React, { useState, useEffect, useCallback } from "react";
import { useAppContext } from "../../../AppContext";
import { SpecificantionsFormat } from "./SpecificantionsFormat";
import { DefaultComp, TabButton } from "../../../Components";
import { Search } from "../../../Components/icons/General";
import './keySpecificationLayout.css'
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuRadioItem,
} from "../../../Components/ui/DropdownMenu/DropdownMenu";

const SpecificationArea = ({ specData }) => {
  const { clickedIssue, specificationsMenu, setSpecificationsMenu, subKeySpecsMenu, setSubKeySpecsMenu, selectIssueDetails, setSelectIssueDetails, isOpenSpecification } =
    useAppContext();
 
  //console.log(specData);

  // 1. the First level menu select: Issue Insights and Key Specs
  const handleSwicthPage = useCallback((e) => {
    setSpecificationsMenu(e.target.value);
  }, [setSpecificationsMenu]);

  // 2. the subMen select of Key Specification
  const handleSubMenuChange = useCallback((e) => {
    setSubKeySpecsMenu(e.target.value);
  }, [setSubKeySpecsMenu]);

  // 3. the dropdown menu of user select display curr issue or Related Issues details
  const handleSelectIssueData = (e, value) => {
    e.preventDefault();
    setSelectIssueDetails(value);
  };

  return (
    <div
      className="rightSideInforArea"
      style={{
        width: isOpenSpecification ? '32%' : '0%',
        opacity: isOpenSpecification ? 1 : 0,
        border: isOpenSpecification ? '1px solid var(--color-gray-200)' : 'none',
        marginLeft: isOpenSpecification ? '18px' : '0px',
        transition: 'width 0.5s ease, opacity 0.5s ease',
      }}
    >
      <div className="specificationsArea">
        {(!clickedIssue || !specData) &&
          <DefaultComp
            title="Start by Searching"
            context="Search by Issue number and keywords to populate this section"
            Icon={Search}
          />
        }

        {clickedIssue && specData && (
          <>
            <div className="menuListWrapper">
              {/* <TabButton label={"Issue Insights"} value={"Issue Insights"} variant={"black"} isDisable={true} isActive={specificationsMenu === "Issue Insights" ? true : false} onClick={(e) => handleSwicthPage(e)} /> */}
              <TabButton label={"Key Specs"} value={"Key Specs"} variant={"black"} isActive={specificationsMenu === "Key Specs" ? true : false} onClick={(e) => handleSwicthPage(e)} />
            </div>

            <div className="subMenuArea">
              <div className="subMenuTabButtonList">
                <TabButton label={"Design Docs"} value={"design_docs"} variant={"white"} isActive={subKeySpecsMenu === "design_docs" ? true : false} onClick={(e) => handleSubMenuChange(e)} />
                <TabButton label={"Standards"} value={"standards"} variant={"white"} isActive={subKeySpecsMenu === "standards" ? true : false} onClick={(e) => handleSubMenuChange(e)} /> 
                {/* <TabButton label={"Scholar Search"} value={"scholar_search"} variant={"white"} isDisable={true} isActive={subKeySpecsMenu === "scholar_search" ? true : false} onClick={(e) => handleSubMenuChange(e)} /> */}
              </div>

              <DropdownMenu>
                <DropdownMenuTrigger>
                  <span className="dropdown-menu-trigger-text">
                    {selectIssueDetails === "current_issue"
                      ? `Issue ID: ${clickedIssue["Issue ID"]}`
                      : "Related Issues"}
                  </span>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuRadioItem
                    checked={selectIssueDetails === "current_issue"}
                    onClick={(e) => handleSelectIssueData(e, "current_issue")}
                  >
                    Issue ID: {clickedIssue["Issue ID"]}
                  </DropdownMenuRadioItem>
                  <DropdownMenuRadioItem
                    checked={selectIssueDetails === "related_issues"}
                    onClick={(e) => handleSelectIssueData(e, "related_issues")}
                  >
                    Related Issues
                  </DropdownMenuRadioItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>

            <div className="borderBottom"></div>

            <SpecificantionsFormat
              specificationsMenu={specificationsMenu}
              subKeySpecsMenu={subKeySpecsMenu}
              selectIssueDetails={selectIssueDetails}
              specData={specData}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default SpecificationArea;
