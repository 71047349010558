import React from "react";
import PropTypes from "prop-types";
const SvgArrowDown = ({ size = 24, color = "var(--icon-color)", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M19.707 11.293a1 1 0 0 1 0 1.414l-7 7a1 1 0 0 1-1.414 0l-7-7a1 1 0 1 1 1.414-1.414L11 16.586V5a1 1 0 1 1 2 0v11.586l5.293-5.293a1 1 0 0 1 1.414 0"
      clipRule="evenodd"
    />
  </svg>
);
SvgArrowDown.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
};
export default SvgArrowDown;
