import React from "react";
import PropTypes from "prop-types";

const BookMark = ({ size = 24, color = "var(--icon-color)", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M9.7587 2H14.2413C15.0463 1.99999 15.7106 1.99998 16.2518 2.04419C16.8139 2.09012 17.3306 2.18868 17.816 2.43597C18.5686 2.81947 19.1805 3.43139 19.564 4.18404C19.8113 4.66937 19.9099 5.18608 19.9558 5.74817C20 6.28938 20 6.95374 20 7.75873V21C20 21.3565 19.8102 21.686 19.5019 21.8649C19.1936 22.0438 18.8134 22.0451 18.5039 21.8682L12 18.1518L5.49614 21.8682C5.18664 22.0451 4.80639 22.0438 4.49807 21.8649C4.18976 21.686 4 21.3565 4 21L4 7.7587C3.99999 6.95373 3.99998 6.28937 4.04419 5.74817C4.09012 5.18608 4.18868 4.66937 4.43597 4.18404C4.81947 3.43139 5.43139 2.81947 6.18404 2.43597C6.66937 2.18868 7.18608 2.09012 7.74817 2.04419C8.28937 1.99998 8.95373 1.99999 9.7587 2ZM7.91104 4.03755C7.47262 4.07337 7.24842 4.1383 7.09202 4.21799C6.7157 4.40973 6.40973 4.7157 6.21799 5.09202C6.1383 5.24842 6.07337 5.47262 6.03755 5.91104C6.00078 6.36113 6 6.94342 6 7.8V19.2768L11.5039 16.1318C11.8113 15.9561 12.1887 15.9561 12.4961 16.1318L18 19.2768V7.8C18 6.94342 17.9992 6.36113 17.9624 5.91104C17.9266 5.47262 17.8617 5.24842 17.782 5.09202C17.5903 4.7157 17.2843 4.40973 16.908 4.21799C16.7516 4.1383 16.5274 4.07337 16.089 4.03755C15.6389 4.00078 15.0566 4 14.2 4H9.8C8.94342 4 8.36113 4.00078 7.91104 4.03755Z"
      clipRule="evenodd"
    />
  </svg>
);

BookMark.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
};

export default BookMark;