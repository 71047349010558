import React, { useMemo, useCallback, useEffect, useState, useRef } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { ContextualMenu } from "@fluentui/react";
import { useAppContext } from "../../../AppContext";
import { Checkbox, Textarea, TablePopWindow} from "../../../Components";
import { generateDynamicStyles } from "../dynamicStyles";
import { Filters } from "../../../Components/icons/General";
import { useToggleFavoriteMutation } from "../../../api/queries";
import StarIcon from "../../../Components/icons/StarIcon";
import { Badge } from "../../../Components/index";
import { useShareIssuesMutation } from "../../../api/queries";
import { useUpdateIssueMutation } from "../../../api/queries";
import toast from 'react-hot-toast';
import { 
  useHideIssuesMutation,
  useUnhideIssuesMutation,
  useSaveIssuesMutation,
  useDeleteSavedIssuesMutation
} from '../../../api/queries';

// add the checkbox column in the left used for add the issue item into star list ==> in the furture
const StartIssueCell = React.memo(({ isAdded, onChange, issueId }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        height: "100%",
      }}
      onClick={(e) => {
        e.stopPropagation();
        onChange(issueId);
      }}
    >
      <StarIcon
        style={{
          width: "20px",
          height: "20px",
          cursor: "pointer",
          marginTop: '1px',
        }}
        isStarred={isAdded}
      />
    </div>
  );
});

// the issue area format: click column header to sorting, clicked to open the similar issue details area, and
const IssueDataGrid = ({ isSharedView, tableViews }) => {
  // get the data from the AppContext API
  const {
    clickedIssue,
    inputIssue,
    setClickedIssue,
    clickedSimilarIssue,
    setClickedSimilarIssue,
    IssuePageSize,
    setIssuePageSize,
    // starIssueArray,
    // setStarIssueArray,
    selectedAreas,
    setSelectedAreas,
    highlightArray,
    sortColumn,
    setSortColumn,
    issueDisplayArray,
    selectColumnArray,
    setSelectColumnArray,
    areaArray,
    allColumn,
    selectStatus,
    allStatus,
    setSelectStatus,
    setIssueDisplayArray,
    checkedRows,
    setCheckedRows,
    //setAreaArray,
    //setIssueRelativeArray,
    currentRowIndex, 
    setCurrentRowIndex,
    setSelectRequestor,
    allRequestor,
    selectRequestor,
    setIssueRelativeArray,
    issueRelativeArray
  } = useAppContext();

  const [contextualMenuProps, setContextualMenuProps] = useState(null); // the each column filter dropdown menu
  const [showEmailSelect, setShowEmailSelect] = useState(false); // the people's email of share feature dropdown menu
  const [selectedIssueIds, setSelectedIssueIds] = useState([]); // the issue ids that user selected for save hide share and star

  // Define handleAreaChange first
  const handleAreaChange = useCallback((area) => {
    setSelectedAreas((prev) => {
      const newSelectedAreas = prev.includes(area) 
        ? prev.filter((a) => a !== area) 
        : [...prev, area];
      return newSelectedAreas;
    });
  }, [setSelectedAreas]);

  const updateIssueMutation = useUpdateIssueMutation();

  // used for the tittle, description and failure mode column open Tab window, but not open pop window at same time
  const checkOpenTab = (e) => {
    if(e.target.target === "_blank"){
      e.stopPropagation();
    }
  }

  const [editingRowId, setEditingRowId] = useState(null); // current row Id
  const [editedValue, setEditedValue] = useState(""); // the current system user eidted value
  const [isActiverHover, setIsActiveHover] = useState(false); // judge whether shows the edit button or not

  const handleEditClick = (rowId, currentValue) => {
    setEditingRowId(rowId); // get the current row issue ID
    setEditedValue(currentValue); // the current value or edited value
  };

  const handleSaveSystem = (rowId) => {
    // the update the data logic
    updateIssueMutation.mutateAsync({
      issueId: rowId,
      field: "System",
      value: editedValue || "",
    })
      .then(() => {
        toast.success("Update request received successfully!");
        setEditingRowId(null); // save and back to default status
      })
      .catch(() => {
        toast.error("Update request failed. Please try again.");
        setEditingRowId(null); // save and back to default status
      });
  };

  const handleCancel = () => {
    setEditingRowId(null); // cancel the edit
  };

  // Keep this merged version inside the component
  const ModifyColumns = useCallback((columns) => {
    return columns.map((column) => {
      if (column.field === "Title" || column.field === "Description" || column.field === "Failure Mode") {
        const keyStr = column.field;
        return {
          ...column,
          renderCell: (params) => (
            <div 
            onClick={(e) => checkOpenTab(e)}
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxHeight: "100%",
            }}
            >
              <span
                dangerouslySetInnerHTML={{ __html: params.row[keyStr] }}
              />
            </div>
          ),
        };
      } else if (column.field === "Cases") {
        return {
          ...column,
          align: "left",
          headerAlign: "left",
          renderCell: (params) => (
            <span
              style={{
                fontWeight: "var(--font-weight-semibold)",
                marginLeft: "5px",
              }}
            >
              {params.value}
            </span>
          ),
        };
      } else if (column.field === "Date") {
        return {
          ...column,
          renderCell: (params) => {
            const timeData = (params.value).split(' ')[0];
    
            return (
              <span
                style={{
                  fontWeight: "var(--font-weight-semibold)",
                }}
              >
                {timeData}
              </span>
            );
          },
        };
      } else if (column.field === "Status") {
        return {
          ...column,
          minWidth: 150,
          flex: 0,
          renderCell: (params) => {
            if (params.value === "Closed") {
              return <Badge type="status" variant="Closed">Closed</Badge>;
            }
            if (params.value === "Open") {
              return <Badge type="status" variant="Open">Open</Badge>;
            }
            if (params.value === "Current") {
              return <Badge type="status" variant="Current">Open</Badge>;
            }
            return <Badge type="status" variant="Other">{params.value}</Badge>;
          },
        };
      } else if (column.field === "System") {
        return {
          ...column,
          renderCell: (params) => {
            const rowId = params.row["Issue ID"];
            const areaList = Array.isArray(params.row.AreaList)
              ? params.row.AreaList
              : params.value.split(", ").map((item) => item.trim());

            if (editingRowId === rowId) {
              // the edit UI: the text area 
              return (
                <div style={{ width: "100%"}}>
                  <Textarea
                    value={editedValue}
                    width={"90%"}
                    height={'55px'}
                    onChange={setEditedValue}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault(); // Prevent line breaks on Enter
                        handleSaveSystem(rowId); // Call the save handler
                      } else if (e.key === "Escape") {
                        handleCancel(); // Call the cancel handler
                      } else if (e.key === " ") {
                        e.stopPropagation(); // STOP The scoll action  
                      }
                    }}
                  />
                </div>
              );
            }

            // the default status with Badges
            return (
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexWrap: "wrap",
                  alignContent: "flex-start",
                  gap: "4px",
                  maxWidth: "100%",
                  maxHeight: "100%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                onMouseEnter={() => setIsActiveHover(rowId)} // shows the edit button
                onMouseLeave={() => setIsActiveHover(null)} // hiden the edit button
              >
                {areaList.map((area, index) => (
                  <Badge
                    key={`${rowId}-${area}-${index}`}
                    type="default"
                    variant={selectedAreas.includes(area) ? "selected" : "areaDefault"}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleAreaChange(area);
                    }}
                  >
                    {area}
                  </Badge>
                ))}

                  {isActiverHover === rowId && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEditClick(rowId, areaList.join(", "))    
                      }}
                      className="editButton"
                      style={{
                        position: "absolute",
                        bottom: "0px",
                        left: "0px",
                      }}
                    >
                      Edit
                    </button>
                  )}
              </div>
            );
          },
        };
      }
      return column;
    });
     // eslint-disable-next-line
  }, [selectedAreas, editedValue, editingRowId, handleAreaChange, handleSaveSystem, handleEditClick]);
  const [isFieldHover, setIsFieldHover] = useState([]);

  // function for user could increase the width of every column
  // ==> fixed the error about issue-summary (flex default): when user Resizing the column issue-summary, removed the flex: 1 attribute
  const handleColumnResize = useCallback(
    (params) => {
      const newWidth = params.width;
      setSelectColumnArray((prevColumns) =>
        prevColumns.map((col) =>
          col.field === params.colDef.field
            ? {
              ...col,
              width: newWidth,
              flex: col.field === "Issue Summary" ? 0 : col.flex,
            }
            : col,
        ),
      );
    },
    [setSelectColumnArray],
  );

  // It will reset the "Issue Summary" column to flex mode when the window is resized.
  useEffect(() => {
    const handleResize = () => {
      setSelectColumnArray((prevColumns) =>
        prevColumns.map((col) =>
          col.field === "Issue Summary"
            ? { ...col, flex: 1, width: undefined }
            : col,
        ),
      );
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [setSelectColumnArray]);

  // the sort function based on user selected
  const sortedIssues = useMemo(() => {
    if (!issueDisplayArray || issueDisplayArray.length === 0) return [];

    if (sortColumn.length === 0) return issueDisplayArray; // Return the original array if no sorting is applied

    // do the sort in the front end:
    // Sort the rest of the items
    return [...issueDisplayArray].sort((a, b) => {
      for (const { field, sort } of sortColumn) {
        const aValue = a[field],
          bValue = b[field];
        if (aValue < bValue) return sort === "asc" ? -1 : 1;
        if (aValue > bValue) return sort === "asc" ? 1 : -1;
      }
      return 0;
    });
  }, [issueDisplayArray, sortColumn]);

  // When calling ModifyColumns, pass handleBadgeRightClick
  const displayColumns = ModifyColumns(selectColumnArray);

  // send the page size into local storage, after user go to other page, it still could save user selected it
  useEffect(() => {
    const savedPageSize = localStorage.getItem("pageSize");
    if (savedPageSize) {
      setIssuePageSize(Number(savedPageSize));
    }
  }, [setIssuePageSize]);

  // the function used selected to add into star issue array list
  const toggleFavoriteMutation = useToggleFavoriteMutation();

  // the column star of issue data table clcik function with backend API
  const handleStarIssueChange = useCallback(
    async (issueId) => {
      let currentIssue =
        clickedIssue && clickedIssue["Issue ID"] === issueId
          ? clickedIssue
          : issueDisplayArray.find((issue) => issue["Issue ID"] === issueId);

      if (!currentIssue) {
        console.error("Issue not found");
        return;
      }

      const newFavoriteStatus = !currentIssue.is_favorite;

      // Update the UI immediately
      if (clickedIssue && clickedIssue["Issue ID"] === issueId) {
        setClickedIssue((prevIssue) => ({
          ...prevIssue,
          is_favorite: newFavoriteStatus,
        }));
      }
      setIssueDisplayArray((prevArray) =>
        prevArray.map((issue) =>
          issue["Issue ID"] === issueId
            ? { ...issue, is_favorite: newFavoriteStatus }
            : issue,
        ),
      );

      try {
        await toggleFavoriteMutation.mutateAsync({
          issueId,
          sourceId: inputIssue,
          isFavorite: !newFavoriteStatus,
        });
      } catch (error) {
        console.error("Error toggling favorite status:", error);
        // Revert the change if the API call fails
        if (clickedIssue && clickedIssue["Issue ID"] === issueId) {
          setClickedIssue((prevIssue) => ({
            ...prevIssue,
            is_favorite: !newFavoriteStatus,
          }));
        }
        setIssueDisplayArray((prevArray) =>
          prevArray.map((issue) =>
            issue["Issue ID"] === issueId
              ? { ...issue, is_favorite: !newFavoriteStatus }
              : issue,
          ),
        );
      }
    },
    [
      clickedIssue,
      inputIssue,
      issueDisplayArray,
      setClickedIssue,
      setIssueDisplayArray,
      toggleFavoriteMutation,
    ],
  );

  // remove the animation and propagation when user click checkbox or star, not open issue details pop window
  const handleCellClick = (params, event) => {
    if (params.field === "Star Issue") {
      event.stopPropagation();
    }
  };

  // click function: when  user clcik the row of issue data table, open the relative issue details pop window
  const handleRowClick = useCallback((params, rowIndex) => {
    if (params.field !== "Star Issue") {
      if (
        clickedSimilarIssue &&
        clickedSimilarIssue["Issue ID"] === params.row["Issue ID"]
      ) {
        setClickedSimilarIssue(null);
        setCurrentRowIndex(0);
      } else {
        setClickedSimilarIssue(params.row);
        setCurrentRowIndex(rowIndex);
      }
    }
  }, // eslint-disable-next-line 
  [clickedSimilarIssue, setClickedSimilarIssue]);
  
  //save all the user clicked area item of dropdown list
  const handleStatusChange = useCallback(
    (e, status) => {
      e.preventDefault();

      setSelectStatus((prev) =>
        prev.includes(status)
          ? prev.filter((a) => a !== status)
          : [...prev, status],
      );
    },
    [setSelectStatus],
  );

  // Save all the user selected all the Requestor of dropdown menu
  const handleRequestorChange = useCallback(
    (requestor) => {
      setSelectRequestor((prev) =>
        prev.includes(requestor) ? prev.filter((p) => p !== requestor) : [...prev, requestor],
      );
    }, [setSelectRequestor]
  );


  // the sort function: user could select and unselect item, back to the relative issue array
  const handleSortFunc = useCallback(
    (field, sortDirection) => {
      // Check if the current sort is already selected
      const isSelected = sortColumn.some(
        (sortItem) => sortItem.field === field && sortItem.sort === sortDirection
      );
  
      if (isSelected) {
        // Clear sorting if already selected
        setSortColumn([]);
      } else {
        // Set the new sorting option
        setSortColumn([{ field, sort: sortDirection }]);
      }
    },
    [sortColumn, setSortColumn]
  );

  // add column selection change ==> the header context drop down men, add the new column in the right of user selected
  const handleAddNewColumn = useCallback(
    (currentColumn, newColumn) => {
      setSelectColumnArray((prev) => {
        // find the current column position index, and add the new column in the right of current column
        const currentIndex = prev.findIndex((c) => c.field === currentColumn);
        let newColumns = [
          ...prev.slice(0, currentIndex + 1),
          {
            field: newColumn,
            headerName: newColumn,
            width: 200,
            minWidth: 200,
          },
          ...prev.slice(currentIndex + 1),
        ];
        return newColumns;
      });
    },
    [setSelectColumnArray],
  );

  // hide column function: hide the user selected column
  const handleHideColumn = useCallback(
    (column) => {
      setSelectColumnArray((prev) => {
        // hide this user selected column
        const newColumns = prev.filter((c) => c.field !== column);
        return newColumns;
      });
    },
    [setSelectColumnArray],
  );

  const handleHeaderClick = (event, field) => {    
    // Base menu items that are always present
    const baseMenuItems = [
      {
        key: "addColumn",
        text: "Add Columns",
        subMenuProps: {
          items: [
            {
              key: "topSpace",
              text: "",
              onRender: () => <div style={{ paddingTop: "4px" }}></div>,
              disabled: true,
            },
            ...allColumn.map((column) => {
              const isColumnSelected = selectColumnArray.some(
                (item) => item.field === column,
              );
              return {
                key: column,
                text: column,
                onRender: () => (
                  <div style={{ paddingLeft: "4px", marginTop: "3px", marginBottom: "3px" }}>
                    <Checkbox
                      id={column}
                      label={column}
                      size="sm"
                      checked={isColumnSelected}
                      disabled={isColumnSelected}
                      onChange={() => {
                        if (!isColumnSelected) {
                          handleAddNewColumn(field, column);
                          setContextualMenuProps(null);
                        }
                      }}
                    />
                  </div>
                ),
              };
            }),
          ],
        },
      },
      {
        key: "hideColumn",
        text: "Hide Column",
        onClick: () => {
          handleHideColumn(field);
          setContextualMenuProps(null);
        },
      },
    ];

    // Conditional sort drop menu item based on field
    let sortMenuItem = null;
    if(field !== "Issue Summary" && field !== "Failure Mode" && field !== "Resolution" && field !== "Status"){
      sortMenuItem = {
        key: "sort",
        text: "Sort",
        subMenuProps: {
          items: [
            {
              key: "sortAsc",
              text: "Sort Ascending",
              style: {
                paddingLeft: "4px", marginTop: "6px", marginBottom: "4px" 
              },
              onRender: () => (
                <div style={{ paddingLeft: "4px", marginTop: "3px", marginBottom: "3px" }}>
                  <Checkbox
                    id="Sort Ascending"
                    label="Sort Ascending"
                    size="sm"
                    type="radio"
                    checked={sortColumn.some(
                      (sortItem) =>
                        sortItem.field === field && sortItem.sort === "asc",
                    )}
                    onChange={() => {
                      handleSortFunc(field, "asc");
                      setContextualMenuProps(null);
                    }}
                  />
                </div>
              ),
            },
            {
              key: "sortDesc",
              text: "Sort Descending",
              onRender: () => (
                <div style={{ paddingLeft: "4px", marginTop: "3px", marginBottom: "3px" }}>
                  <Checkbox
                    id="Sort Descending"
                    label="Sort Descending"
                    type="radio"
                    checked={sortColumn.some(
                      (sortItem) =>
                        sortItem.field === field && sortItem.sort === "desc",
                    )}
                    onChange={() => {
                      handleSortFunc(field, "desc");
                      setContextualMenuProps(null);
                    }}
                  />
                </div>
              ),
            },
          ],
        },
      }
    }

    // Conditional filter drop menu item based on field
    let filterMenuItem = null;
    if (field === "Status") {
      filterMenuItem = {
        key: "filter",
        text: "Filter",
        subMenuProps: {
          styles: {
            root: {
              maxHeight: "300px", // setup the max height
              overflowY: "auto", // auto display scoll bar
            },
          },
          items: [
            {
              key: "topSpace",
              text: "",
              onRender: () => <div style={{ paddingTop: "4px" }}></div>,
              disabled: true,
            },
            ...allStatus.map((status) => ({
              key: status,
              text: status,
              onRender: () => (
                <div style={{ paddingLeft: "4px", marginTop: "3px", marginBottom: "3px" }}>
                  <Checkbox
                    id={status}
                    label={status}
                    size="sm"
                    checked={selectStatus.includes(status)}
                    onChange={(e) => {
                      handleStatusChange(e, status);
                      setContextualMenuProps(null);
                    }}
                  />
                </div>
              ),
            })),
          ],
        },
      };
    } else if (field === "System") {
      filterMenuItem = {
        key: "filter",
        text: "Filter",
        subMenuProps: {
          styles: {
            root: {
              maxHeight: "300px", // setup the max height
              overflowY: "auto", // auto display scoll bar
            },
          },
          items: [
            {
              key: "topSpace",
              text: "",
              onRender: () => <div style={{ paddingTop: "4px" }}></div>,
              disabled: true,
            },
            ...areaArray.map((area) => ({
              key: area,
              text: area,
              onRender: () => (
                <div style={{ paddingLeft: "4px", marginTop: "3px", marginBottom: "3px" }}>
                  <Checkbox
                    id={area}
                    label={area}
                    size="sm"
                    checked={selectedAreas.includes(area)}
                    onChange={() => {
                      handleAreaChange(area);
                      setContextualMenuProps(null);
                    }}
                  />
                </div>
              ),
            })),
          ],
        },
      };
    } else if(field === "Requestor"){
      filterMenuItem = {
        key: "filter",
        text: "Filter",
        subMenuProps: {
          styles: {
            root: {
              maxHeight: "300px", // setup the max height
              overflowY: "auto", // auto display scoll bar
            },
          },
          items: [
            {
              key: "topSpace",
              text: "",
              onRender: () => <div style={{ paddingTop: "4px" }}></div>,
              disabled: true,
            },
            ...allRequestor.map((requestor) => ({
              key: requestor,
              text: requestor,
              onRender: () => (
                <div style={{ paddingLeft: "4px", marginTop: "3px", marginBottom: "3px" }}>
                  <Checkbox
                    id={requestor}
                    label={requestor}
                    size="sm"
                    checked={selectRequestor.includes(requestor)}
                    onChange={() => {
                      handleRequestorChange(requestor);
                      setContextualMenuProps(null);
                    }}
                  />
                </div>
              ),
            })),
          ],
        },
      }
    }

    // Create final menu items array by inserting filter item after sort if it exists
    let menuItems = baseMenuItems;
    if(filterMenuItem){
      menuItems.unshift(filterMenuItem);
    }

    if(sortMenuItem){
      menuItems.unshift(sortMenuItem)
    }

    setContextualMenuProps({
      items: menuItems,
      target: event.currentTarget,
      onDismiss: () => setContextualMenuProps(null),
      directionalHint: 6,
      directionalHintFixed: true,
      gapSpace: 1,
      alignTargetEdge: true,
    });
  };

  const rows = (tableViews === "Saved" || tableViews === "Hidden")
  ? sortedIssues
  : [
      ...(clickedIssue ? [clickedIssue] : []),
      ...sortedIssues.filter(
          (item) => item["Issue ID"] !== clickedIssue?.["Issue ID"],
      ),
  ];

  //the header area checkbox checkbox ==> select all or cancel all
  const handleHeaderCheckboxChange = () => {
    setCheckedRows((prev) => {
      // if all selected, cancel all the rows
      if (prev.size === rows.length) {
        return new Set();
      }
      // if not select all the rows
      return new Set(rows.map((row) => row["Issue ID"]));
    });
  };

  // Handle checkbox change of issue table boday
  const handleCheckboxChange = (issueId) => {
    setCheckedRows((prev) => {
      const newChecked = new Set(prev);
      if (newChecked.has(issueId)) {
        newChecked.delete(issueId);
      } else {
        newChecked.add(issueId);
      }
      return newChecked;
    });
  };


  const handleMouseEnter = (headerName) => {
    setIsFieldHover((prev) => [...prev, headerName]);
  };
  
  const handleMouseLeave = (headerName) => {
    setIsFieldHover((prev) => prev.filter((name) => name !== headerName));
  };

  // Change evey column header area ==> add the menu icon inside
  const displayColumnswithCustomHeader = displayColumns.map((col) => ({
    ...col,
    sortable: false,
    renderHeader: (params) => {
      if (!params || !params.colDef) {
        return null; // avoid the params.colDef is undefind and have errors
      }

      const isHovered = isFieldHover.includes(params.colDef.headerName);

      return (
        <div 
          style={{ display: "flex", width: '100vw', alignItems: "center", justifyContent: "space-between"}}
          onMouseEnter={() => handleMouseEnter(params.colDef.headerName)}
          onMouseLeave={() => handleMouseLeave(params.colDef.headerName)}
        >
          <span>{params.colDef.headerName}</span>
          <Filters
            size={16}
            color= {isHovered ? "var(--color-dark-gray-900)" : "var(--color-dark-gray-50)" }
            onClick={(e) => handleHeaderClick(e, params.colDef.field)}
            style={{
              cursor: 'pointer',
            }}
          />
        </div>
      );
    },
  }));

  const { mutateAsync: save } = useSaveIssuesMutation();

  const handleSave = async () => {
    const selectedIssueIds = Array.from(checkedRows);
    const sourceId = clickedIssue?.["Issue ID"] || null;
    
    try {
      await save({ issueIds: selectedIssueIds, sourceId });
      setCheckedRows(new Set());
    } catch (error) {
      console.error('Error saving issues:', error);
    }
  };

  const shareIssuesMutation = useShareIssuesMutation();

  // Function to handle "Share" button click
  const handleShare = (selectedEmails) => {
    if (selectedEmails.length === 0) {
      setSelectedIssueIds(Array.from(checkedRows));
      setShowEmailSelect(true);
      setCheckedRows(new Set());
      return;
    }
    
    const issueIds = selectedIssueIds;
    const shareUrl = `${window.location.origin}/shared?issuelist=${issueIds.join(',')}`;
    
    shareIssuesMutation.mutateAsync({
      emails: selectedEmails,
      shareUrl: shareUrl
    })
      .then((response) => {
        if (!response) return;
        
        console.log("Share API Response:", response);
        setShowEmailSelect(false);
        setSelectedIssueIds([]);
        // TODO: Show success notification
      })
      .catch((error) => {
        console.error("Error sharing issues:", error);
        // TODO: Show error notification
      });
  };
  
  // Function to handle "Hide" button click
  const { mutateAsync: hide } = useHideIssuesMutation();

  const handleHide = async () => {
    const selectedIssueIds = Array.from(checkedRows);
    const sourceId = clickedIssue?.["Issue ID"] || null;
    
    try {
      await hide({ issueIds: selectedIssueIds, sourceId });
      
      // Clear the checked rows
      setCheckedRows(new Set());
      
      // Remove hidden issues from both display array and relative array
      const filterOutHidden = array => 
        array.filter(issue => !selectedIssueIds.includes(issue["Issue ID"]));
      
      setIssueDisplayArray(filterOutHidden);
      setIssueRelativeArray(filterOutHidden);

      // If the clicked issue was hidden, clear it
      if (clickedIssue && selectedIssueIds.includes(clickedIssue["Issue ID"])) {
        setClickedIssue(null);
      }

      // If the clicked similar issue was hidden, clear it
      if (clickedSimilarIssue && selectedIssueIds.includes(clickedSimilarIssue["Issue ID"])) {
        setClickedSimilarIssue(null);
      }
      
    } catch (error) {
      console.error('Error hiding issue(s):', error);
      toast.error('Failed to hide issue(s)');
    }
  };

  const { mutateAsync: unhide } = useUnhideIssuesMutation();

  const handleUnhide = async () => {
    const selectedIssueIds = Array.from(checkedRows);
    
    try {
      await unhide(selectedIssueIds);
      setCheckedRows(new Set());
    } catch (error) {
      console.error('Error unhiding issues:', error);
    }
  };

  // Set clickedSimilarIssue to null to close the opened issue
  const handleCloseIssue = useCallback(() => {
    setClickedSimilarIssue(null);
  }, [setClickedSimilarIssue]);

   // the keyboard function used for issue data table
  const handleKeyDown = useCallback((event) => {
    const { key, target } = event;

    const isInputField =
      target.tagName === "INPUT" ||
      target.tagName === "TEXTAREA" ||
      target.isContentEditable;

    if (isInputField) {
      return; // jump the below functio ==> user was inputing the text in search field
    }
  
    switch (key) {
      case 'ArrowDown':
        event.preventDefault();
        setCurrentRowIndex((prev) => 
          Math.min(prev + 1, rows.length - 1)
        );  // Increment index on ArrowDown
        break;
        
      case 'ArrowUp':
        event.preventDefault();
        setCurrentRowIndex((prev) => 
          Math.max(prev - 1, 0)
        );  // Decrement index on ArrowUp
        break;

      case 'PageUp':
        event.preventDefault();
        setCurrentRowIndex((prev) => Math.max(prev - 5, 0));
        break;
      
      case 'PageDown':
        event.preventDefault();
        setCurrentRowIndex((prev) => Math.min(prev + 5, rows.length - 1));
        break;

      case 'Home':
        event.preventDefault();
        setCurrentRowIndex(0);
        break;
      
      case 'End':
        event.preventDefault();
        setCurrentRowIndex(rows.length - 1);
        break;
        
      case ' ': // space
        event.preventDefault();
        const currentRow = rows[currentRowIndex];
        if (currentRow) {
          handleCheckboxChange(currentRow["Issue ID"]);
        }
        break;
        
      case 'Enter': // if use Enter, it will conflict with search field input issue id to search
        event.preventDefault();
        const selectedRow = rows[currentRowIndex];
        if (selectedRow) {
          handleRowClick({ row: selectedRow }, currentRowIndex);
        }
        break;
        
      case 's':
      case 'S':
        if (event.ctrlKey || event.metaKey) {
          event.preventDefault();
          const currentRow = rows[currentRowIndex];
          if (currentRow) {
            handleStarIssueChange(currentRow["Issue ID"]);
          }
        }
        break;
  
      case 'a':
      case 'A':
        if (event.ctrlKey || event.metaKey) {
          event.preventDefault();
          // Check if all checkboxes are selected
          const allIssueIds = rows.map(row => row["Issue ID"]);
          const allSelected = allIssueIds.every(issueId => checkedRows.has(issueId));
          
          if (allSelected) {
            // If all are selected, deselect them
            setCheckedRows(new Set());
          } else {
            // Otherwise, select all
            setCheckedRows(new Set(allIssueIds));
          }
        }
        break;

      // Explicitly clear all selections with Ctrl + D
      case 'd':
        case 'D':
          if (event.ctrlKey || event.metaKey) {
            event.preventDefault();
            setCheckedRows(new Set());
          }
          break;
  
      case 'Escape':
        event.preventDefault();
        handleCloseIssue(); 
        break;
      default:
        break;
    }
     // eslint-disable-next-line
  }, [currentRowIndex, rows, handleCheckboxChange, handleRowClick, handleStarIssueChange, handleCloseIssue]);
 
  // the keyboard listner event
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const { mutateAsync: deleteSaved } = useDeleteSavedIssuesMutation();

  const handleDelete = async () => {
    const selectedIssueIds = Array.from(checkedRows);
    
    try {
      await deleteSaved(selectedIssueIds);
      setCheckedRows(new Set());
    } catch (error) {
      console.error('Error deleting saved issues:', error);
    }
  };

  // the main render area
  return (
    <>
      {/* Injecting dynamic styles */}
      <style>
        {generateDynamicStyles(
          highlightArray["Failure Mode"],
          highlightArray["Components"],
          highlightArray["Failure Condition"],
          highlightArray["After Effects"],
          highlightArray["Specification"],
        )}
      </style>

      {checkedRows.size > 0 && (
        <TablePopWindow
          selectedCount={checkedRows.size}
          checkedRows={checkedRows}
          onSave={tableViews === 'Saved' ? handleDelete : handleSave}
          onShare={handleShare}
          onHide={tableViews === 'Hidden' ? handleUnhide : handleHide}
          teamMember={[
            { email: "mat.khajavi@apiphany.ai", name: "Mat Khajavi"},
            { email: "rebecca@apiphany.ai", name: "Rebecca"},
            { email: "ben.larue@apiphany.ai", name: "BenLarue"},
          ]}
          tableView={tableViews}
        />
      )}
 
      <DataGrid
        rows={rows}
        columns={[
          {
            field: "Checkbox",
            headerName: "",
            width: 50,
            minWidth: 50,
            maxWidth: 50,
            sortable: false,
            cellClassName: "checkbox-cell",
            renderHeader: () => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  height: "100%",
                  padding: "6px",
                }}
              >
                <Checkbox
                  id="header-checkbox"
                  size="sm"
                  checked={checkedRows.size === rows.length && rows.length > 0}
                  indeterminate={
                    checkedRows.size > 0 && checkedRows.size < rows.length
                  }
                  onChange={(e) => {
                    e.stopPropagation();
                    handleHeaderCheckboxChange();
                  }}
                />
              </div>
            ),
            renderCell: (params) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  height: "100%",
                  padding: "1px 6px",
                  cursor: "pointer",
                }}
              >
                <Checkbox
                  id={`checkbox-${params.row["Issue ID"]}`}
                  size="sm"
                  checked={checkedRows.has(params.row["Issue ID"])}
                  onChange={() => handleCheckboxChange(params.row["Issue ID"])}
                />
              </div>
            ),
          },
          {
            field: "Star Issue",
            headerName: "",
            width: 50,
            minWidth: 50,
            maxWidth: 50,
            sortable: false,
            renderCell: (params) => (
              <StartIssueCell
                isAdded={params.row.is_favorite}
                onChange={() => handleStarIssueChange(params.row["Issue ID"])}
                issueId={params.row["Issue ID"]}
              />
            ),
          },
          ...displayColumnswithCustomHeader,
        ]}
        getRowId={(row) => row["Issue ID"]}
        getRowClassName={(params) => {
          const isCurrentRow = params.row["Issue ID"] === rows[currentRowIndex]?.["Issue ID"];
          const isClickedIssue = params.row["Issue ID"] === clickedIssue?.["Issue ID"];
          return `${(isCurrentRow) ? 'current-row' : ''} ${isClickedIssue ? 'clicked-issue-row' : ''}`;
        }}
        columnHeaderHeight={40}
        rowHeight={100}
        onColumnResize={handleColumnResize}
        onCellClick={handleCellClick}
        onRowClick={(params) => {
          const { id } = params; // get the id of current row or user selected
          const rowIndex = rows.findIndex((r) => r["Issue ID"] === id); // get the row index
          handleRowClick(params, rowIndex); // send the rowIndex into the row click function
        }}
        paginationModel={IssuePageSize}
        onPaginationModelChange={setIssuePageSize}
        pageSizeOptions={[25, 35, 50]}
        disableColumnMenu
        disableColumnFilter
        disableColumnSelector
        disableExtendRowFullWidth
        disableRowSelectionOnClick
        disableSelectionOnClick
        isRowSelectable={() => false}
        componentsProps={{
          row: {
            onMouseDown: (event) => {
              event.preventDefault();
            },
          },
        }}
        sx={{
          maxHeight: isSharedView ? '100vh' : 'calc(100vh - 196px)',
          height: isSharedView ? '100vh' : undefined,
          marginTop: isSharedView ? 0 : undefined,
          border: 0,
          overflow: "hidden",
          "& .current-row": {
            backgroundColor: "var(--danger-50) !important", // the current row of keyboard button
          },

          "& .MuiDataGrid-overlay": {
            display: "none",
          },
          "& .MuiDataGrid-cell": {
            fontFamily: "var(--font-family-body)",
            fontSize: "var(--font-size-sm)",
            fontWeight: "var(--font-weight-regular)",
            color: "var(--color-gray-900)", // rgba(0, 0, 0, 0.50) from figma, too light
            padding: "5px 12px",
            cursor: "pointer",
            userSelect: "none",
            transition: "width 0.3s ease", // Add transition to the cell itself
            whiteSpace: "normal", // Allow wrapping
            wordBreak: "break-word", // Break words if too long
            lineHeight: "var(--line-height-sm)", // Adjust line height for multi-line text
            display: "-webkit-box",
            WebkitLineClamp: 3,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxHeight: "calc(3.7 * var(--line-height-md))",
          },
          "& .MuiDataGrid-cell.Mui-selected": {
            border: "1px solid var(--color-gray-300) !important", 
            borderRadius: "4px", // the radius of user selected cells 
          },
          "& .MuiDataGrid-columnHeaderContainer": {
            //the header area
            borderBottom: "none",
            transition: "width 0.3s ease",
          },
          "& .MuiDataGrid-columnHeader": {
            //the every column header area fonts attrubite
            fontFamily: "var(--font-family-body)",
            fontSize: "var(--font-size-sm)",
            backgroundColor: "var(--color-dark-gray-50)",
            color: "var(--color-gray-800)",
            paddingLeft: "12px",
            fontWeight: "bold",
            userSelect: "none",
            transition: "width 0.3s ease",
          },
          "& .MuiDataGrid-footerContainer": {
            //the every column footer area fonts attrubite
            fontFamily: "var(--font-family-body)",
            cursor: "default",
            fontSize: "14px",
            color: "#424242",
            userSelect: "none",
            height: "40px",
            justifyContent: "center", // 居中对齐
          },
          // change the rows background color
          "& .MuiDataGrid-row:nth-of-type(odd)": {
            backgroundColor: "var(--color-dark-gray-50)", // Odd row background color: #FBFBFB
          },
          "& .MuiDataGrid-row:nth-of-type(even)": {
            backgroundColor: "var(--color-white)", // Even row background color: #FFFFFF
          },
          // select any row, change the background color to #F0F0F0
          "& .MuiDataGrid-row.Mui-selected": {
            backgroundColor: "var(--danger-50) !important",
          },
          "& .MuiDataGrid-row:hover": {
            // the mouse move will change the rows background color
            backgroundColor: "#F0F0F0",
          },
          // Styles only for the clicked issue row （first row)
          "& .clicked-issue-row": {
            backgroundColor: "var(--brand-secondary-50) !important", // Highlight color for clicked issue
          },
          "& .clicked-issue-row .MuiDataGrid-cell": {
            color: "#424242", // Font color for clicked issue
          },
          '& .clicked-issue-row .MuiDataGrid-cell[data-field="Status"], & .clicked-issue-row .MuiDataGrid-cell[data-field="System"]':
          {
            color: "#424242 !important",
          },
          "& .clicked-issue-row.Mui-selected": {
            // not allowed selection to change first row color
            backgroundColor: "var(--warning-50) !important",
            color: "#424242 !important", // Keep text color as well
          },
        }}
      />
      {contextualMenuProps && <ContextualMenu {...contextualMenuProps} />}
    </>
  );
};

export default IssueDataGrid;
