// the cutomize card used to display the data of key specification area
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./SpeciDataCard.css";
import ViewButton from "../ViewButton/ViewButton";
import {Share, SaveIcon, AIautoFill} from "../../icons/General/index";
import { ThumbsDown } from "../../icons/Alerts/index"
import { Links } from "../../icons/General/index"

const SpeciDataCard = ({
    title,
    context,
    sourceLinks = [],
    defaultWidth = "541px",
    buttonArray = [
        {
            label: "Share",
            isClicked: false,
            buttonClick: () => console.log("Share clicked"),
            icon: Share,
            IconSize: 16
        },
        {
            label: "Save",
            isClicked: false,
            buttonClick: () => console.log("Save clicked"),
            icon: SaveIcon,
            IconSize: 16
        },
        {
            label: null,
            isClicked: false,
            buttonClick: () => console.log("Thumbs down clicked"),
            icon: ThumbsDown,
            IconSize: 16
        },
        {
            label: null,
            isClicked: false, 
            buttonClick: () => console.log("Sparkles clicked"),
            icon: AIautoFill,
            IconSize: 16
        }
    ],
    openDataCard,
    setOpenDataCard,
}) => {

    const [isExpanded, setIsExpanded] = useState(false); // the data card was open or not

    //  memorize which data card user open it
    useEffect(() => {
        if (Array.isArray(openDataCard) && openDataCard.includes(title)) {
            setIsExpanded(true);
        } else{
            setIsExpanded(false);
        }
    }, [openDataCard, title])


    // the open data card function
    const toggleExpand = () => {
        setIsExpanded((prevIsOpen) => {
            if (prevIsOpen) {
                setOpenDataCard((prevOpenItem) => prevOpenItem.filter((item) => item !== title));
            } else {
                setOpenDataCard((prevOpenItem) => [...prevOpenItem, title]);
            }
            return !prevIsOpen;
        });
    };

    return (
        <div className="datacard" style={{ width: defaultWidth}}>
            <span className="titleText">{title}</span>
            <span className={`context ${isExpanded ? "expanded" : "collapsed"}`} onClick={toggleExpand}>{context}</span>

            {Object.keys(sourceLinks).length > 0 && Object.entries(sourceLinks).map(([key, value], index) => (
                <a
                    key={`${key} ${index}`}
                    className="sourceText"
                    href={value}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {key} <Links size={14} color="var(--blue-700)"/>
                </a>
            ))}


            {/* Temporary Hide all the button: Share, Save, ThumbDown, and AI button */}
            {/* <div className="buttonArea">
                {buttonArray.map((button, index) => (
                    <ViewButton 
                        key={index}
                        label={button.label}
                        onClick={button.buttonClick}
                        icon={button.icon}
                        variant={button.icon !== AIautoFill ? "default" : "AI"}
                        IconSize={button.IconSize}
                    />
                ))}
            </div> */}
        </div>
    );
};

SpeciDataCard.propTypes = {
    title: PropTypes.string.isRequired,
    context: PropTypes.string.isRequired,
    defaultWidth: PropTypes.string,
    sourceLinks: PropTypes.arrayOf(PropTypes.string),
    buttonArray: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            buttonClick: PropTypes.func,
            isClicked: PropTypes.bool,
            icon: PropTypes.elementType,
            IconSize: PropTypes.number
        })
    ),
    openDataCard: PropTypes.array.isRequired,
    setOpenDataCard: PropTypes.func.isRequired,
};

export default SpeciDataCard;