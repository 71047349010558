// Apiphany Customize AccordionItem
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import "./AccordionItems.css";
import { ChevronDownIcon } from "../../icons";

const AccordionItems = ({
    title,
    defaultWidth = "565px",
    children = [],
    openItem,
    setOpenItem,
}) => {
    const [isOpen, setIsOpen] = useState(false); // the state to mark the accordionItem was open or closed
    const titleRef = useRef(null); // Reference to measure title element

    useEffect(() => {
        if (Array.isArray(openItem) && openItem.includes(title)) {
            setIsOpen(true);
        } else{
            setIsOpen(false);
        }
        //console.log(openItem);
    }, [openItem, title]);

    // the open accordionItem function
    const toggleAccordion = () => {
        setIsOpen((prevIsOpen) => {
            if (prevIsOpen) {
               setOpenItem((prevOpenItem) => prevOpenItem.filter((item) => item !== title));
            } else {
               setOpenItem((prevOpenItem) => [...prevOpenItem, title]);
            }
            return !prevIsOpen;
        });
    };

    return (
        <div className="accordion-item">
                <div className={`accordion-header ${isOpen ? "open" : ""}`} onClick={toggleAccordion} style={{ width: defaultWidth }}>
                    <div className="accordion-leftHeader">
                        <span className={`accordion-title ${isOpen ? "" : "NotOpen"}`} ref={titleRef}>
                            {title}
                        </span>
                        <div className="badge">{children.length || 1} </div> {/* the count of children length*/}
                    </div>

                    <div className={`arrow-icon ${isOpen ? "open" : ""}`}>
                        <ChevronDownIcon />
                    </div>
                </div>
                 
                {isOpen && <div className={`accordion-content ${isOpen ? "show" : "hide"}`}>
                    {children}
                </div>}
        </div>
    );
};

AccordionItems.propTypes = {
    title: PropTypes.string.isRequired,
    defaultWidth: PropTypes.string,
    children: PropTypes.node.isRequired,
    openItem: PropTypes.array.isRequired,
    setOpenItem: PropTypes.func.isRequired,
};

export default AccordionItems;

