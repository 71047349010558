import React from "react";
import PropTypes from "prop-types";
const SvgThumbsDown = ({
  size = 24,
  color = "var(--icon-color)",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M20.032 3.024C19.75 3 19.377 3 18.8 3H18v9h.8c.576 0 .949 0 1.232-.024.272-.022.372-.06.422-.085a1 1 0 0 0 .437-.437c.025-.05.063-.15.085-.422C21 10.75 21 10.377 21 9.8V5.2c0-.577 0-.949-.024-1.232-.022-.272-.06-.373-.085-.422a1 1 0 0 0-.437-.437c-.05-.025-.15-.063-.422-.085M16 12.878V3H8.118c-.746 0-1.25 0-1.642.03-.38.03-.581.082-.728.148a2 2 0 0 0-.85.73c-.087.134-.17.325-.256.696-.09.383-.166.881-.28 1.618l-.523 3.4c-.15.97-.25 1.635-.285 2.15-.034.507.007.765.074.94a2 2 0 0 0 .88 1.027c.164.093.413.172.918.216.515.044 1.187.045 2.169.045h.837c.252 0 .498 0 .706.017.229.019.499.063.77.201a2 2 0 0 1 .874.874c.138.271.182.541.201.77.017.208.017.454.017.706v2.966c0 .771.596 1.404 1.352 1.462l3.312-7.452.032-.073c.08-.18.173-.394.304-.593M18.839 1c.527 0 .981 0 1.356.03.395.033.789.104 1.167.297a3 3 0 0 1 1.311 1.311c.193.378.264.772.296 1.167.031.375.031.83.031 1.356V9.84c0 .527 0 .981-.03 1.356-.033.395-.104.789-.297 1.167a3 3 0 0 1-1.311 1.311c-.378.193-.772.264-1.167.296-.375.031-.83.031-1.357.031h-.798c-.201 0-.305 0-.38.005h-.006l-.003.007a7 7 0 0 0-.16.344l-3.36 7.562A1.82 1.82 0 0 1 12.466 23 3.466 3.466 0 0 1 9 19.534V16.6a8 8 0 0 0-.011-.588l-.014-.002A8 8 0 0 0 8.4 16h-.85c-.926 0-1.684 0-2.296-.053-.63-.054-1.209-.171-1.734-.47a4 4 0 0 1-1.76-2.052c-.216-.564-.244-1.154-.201-1.785.04-.613.156-1.363.297-2.278l.535-3.481c.106-.69.195-1.264.303-1.73.113-.487.264-.929.53-1.337a4 4 0 0 1 1.699-1.457c.443-.201.903-.283 1.401-.32C6.801 1 7.382 1 8.08 1z"
      clipRule="evenodd"
    />
  </svg>
);
SvgThumbsDown.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
};
export default SvgThumbsDown;
