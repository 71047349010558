import React from "react";
import PropTypes from "prop-types";
const SvgDelets = ({ size = 24, color = "var(--icon-color)", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#101828"
      fillRule="evenodd"
      d="M8 3a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1M4.992 5H3a1 1 0 0 0 0 2h1.064l.642 9.625c.05.756.092 1.381.167 1.89.078.53.201 1.014.458 1.465a4 4 0 0 0 1.731 1.62c.468.226.959.317 1.493.36.512.04 1.139.04 1.896.04h3.098c.757 0 1.384 0 1.896-.04.534-.043 1.026-.134 1.493-.36a4 4 0 0 0 1.73-1.62c.258-.451.381-.935.46-1.465.074-.509.116-1.134.166-1.89L19.936 7H21a1 1 0 1 0 0-2H4.992m12.94 2H6.068l.63 9.453c.054.805.09 1.35.153 1.771.06.41.133.619.217.766a2 2 0 0 0 .865.81c.153.074.367.133.779.166.424.033.97.034 1.778.034h3.018c.807 0 1.354 0 1.778-.034.412-.033.626-.092.779-.166a2 2 0 0 0 .865-.81c.084-.147.157-.357.217-.766.062-.42.1-.966.153-1.771zM10 9.5a1 1 0 0 1 1 1v5a1 1 0 1 1-2 0v-5a1 1 0 0 1 1-1m4 0a1 1 0 0 1 1 1v5a1 1 0 1 1-2 0v-5a1 1 0 0 1 1-1"
      clipRule="evenodd"
    />
  </svg>
);
SvgDelets.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
};
export default SvgDelets;
