// the Icons misc (difference background color icon) file
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./IconsMisc.css";
import classNames from "classnames";

const IconsMisc = ({
    Icon,
    size = "sm",
    color = "green", 
}) => {
    const className = classNames(
        "IconArea",
        `IconArea--${size}`,
        `IconArea--${color}`,
    );

    const [svgColor, setSvgColor] = useState("");

    useEffect(() => {
        if(color === "green"){
            setSvgColor("var(--brand-secondary-500)");
        } else if(color === "red" || color === "yellow"){
            setSvgColor("var(--danger-600")
        } else if(color === "gray"){
            setSvgColor("var(--color-gray-500)")
        } else if(color === "darkGreen"){
            setSvgColor("var(--success-600)")
        }
    }, [color]);

    return (
        <div className={className}>
            <Icon color={svgColor}/>
        </div>
    );
};

IconsMisc.propTypes = {
    size: PropTypes.oneOf(["sm", "md", "lg", "xl"]).isRequired,
    color: PropTypes.oneOf(["green", "red", "gray", "yellow", "darkGreen"]).isRequired,
    Icon: PropTypes.elementType.isRequired,
};

export default IconsMisc;