/*
  this is login component: to check the user enter the username and password
*/
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./layout.css";
import { useAppContext } from "../../../AppContext";
import companyLogoWithoutName from "../../../image/ApiphanyLogo/ApiphanyIconWithoutName.jpg";
import { FormHeader } from "../../../index";
import { useNavigate } from "react-router-dom";
import { InputFields, Button } from "../../../Components";
import { Checkbox } from "../../../Components/ui/Checkbox/index";
import { useLoginMutation } from "../../../api/queries";
import useAuthStore from "../../../stores/useAuthStore";

const Login = ({ onLogin }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [errorState, setErrorState] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const {
    setRegistrationSuccess,
    setSelectedMenuItem,
    isRememberMe,
    setIsRemeberMe,
  } = useAppContext();
  const { isAuthenticated, setAuth } = useAuthStore();

  const loginMutation = useLoginMutation();

  const from = location.state?.from || "/";

  useEffect(() => {
    if (isAuthenticated) {
      navigate(from, { replace: true });
    }
  }, [isAuthenticated, navigate, from]);

  useEffect(() => {
    const rememberedEmail = localStorage.getItem("rememberedEmail");
    const rememberedPassword = localStorage.getItem("rememberedPassword");
    if (rememberedEmail) {
      setEmail(rememberedEmail);
      setPassword(rememberedPassword);
      setIsRemeberMe(true);
    }
  }, [setIsRemeberMe]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(false);
    setErrorMessage("");

    try {
      const response = await loginMutation.mutateAsync({ email, password });

      setRegistrationSuccess(false);
      setSelectedMenuItem("discover");

      if (isRememberMe) {
        localStorage.setItem("rememberedEmail", email);
        localStorage.setItem("rememberedPassword", password);
      } else {
        localStorage.removeItem("rememberedEmail");
        localStorage.removeItem("rememberedPassword");
      }

      const user = {
        id: response.user.id,
        firstName: response.user.first_name,
        lastName: response.user.last_name,
        email: response.user.email,
        type: response.user.type,
      };

      setAuth(user, response.access_token);

      onLogin(user);

      navigate(from, { replace: true });
    } catch (error) {
      setError(true);
      setErrorMessage(
        error.response?.data?.message || "An error occurred during login.",
      );
    }
  };

  //function to save remember account or not
  const handleClickedRemember = () => {
    setIsRemeberMe(!isRememberMe);
  };

  return (
    <div className="container">

      <div className="loginComp">
        <div className="login-container">
          <FormHeader
            title="Log in to your account"
            subtitle="Welcome back! Please enter your details."
            className="form-header"
            companyImage={companyLogoWithoutName}
          />

          <form onSubmit={handleSubmit}>
            {error &&
              (errorState === "invalid email" || errorState === "wrong email") ? (
              <InputFields
                height="24px"
                hint=""
                errorMessage={
                  errorState === "invalid email"
                    ? "Please enter a valid email address"
                    : "Incorrect email or password. Please try again"
                }
                label="Email"
                placeholder="Enter your email"
                type="errorWithBlack"
                width="334px"
                value={email}
                hasIcon="true"
                onChange={(e) => setEmail(e.target.value)}
                isPassword={false}
              />
            ) : (
              <InputFields
                height="24px"
                hint=""
                label="Email"
                placeholder="Enter your email"
                type="generalWithBlack"
                width="334px"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                isPassword={false}
              />
            )}

            {error &&
              (errorState === "wrong password" || errorState === "wrong email") ? (
              <InputFields
                height="24px"
                hint="Incorrect email or password. Please try again"
                label="Password"
                placeholder="Enter your password"
                type="errorWithBlack"
                width="334px"
                hasIcon="true"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                isPassword={true}
              />
            ) : (
              <InputFields
                height="24px"
                hint=""
                label="Password"
                placeholder="Enter your password"
                type="generalWithBlack"
                width="334px"
                isRequired={true}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                isPassword={true}
              />
            )}

            <div className="addtionArea">
              <Checkbox
                id="default-checkbox"
                label="Remember me"
                checked={isRememberMe ? true : false}
                onChange={handleClickedRemember}
                size="sm"
                type="checkbox"
              />

              <label
                style={{
                  color: "var(--brand-secondary-700)",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                Forgot Password?
              </label>
            </div>

            <Button type="submit" variant="primary" size="small" width="100%">
              Sign in
            </Button>
          </form>
        </div>
      </div>


      <div className="image-container">
        
      </div>
    </div>
  )
};

export default Login;
