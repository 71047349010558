import React from "react";
import PropTypes from "prop-types";

const Links = ({ size = 24, color = "var(--icon-color)", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M11.0005 2.51468C13.3436 0.171538 17.1426 0.171537 19.4857 2.51468C21.8289 4.85783 21.8289 8.65682 19.4857 11L18.0715 12.4142C17.681 12.8047 17.0478 12.8047 16.6573 12.4142C16.2668 12.0237 16.2668 11.3905 16.6573 11L18.0715 9.58575C19.6336 8.02365 19.6336 5.49099 18.0715 3.9289C16.5094 2.3668 13.9768 2.3668 12.4147 3.9289L11.0005 5.34311C10.6099 5.73363 9.97676 5.73363 9.58624 5.34311C9.19571 4.95259 9.19571 4.31942 9.58624 3.9289L11.0005 2.51468ZM15.2076 6.79284C15.5981 7.18336 15.5981 7.81653 15.2076 8.20705L8.20756 15.207C7.81704 15.5976 7.18387 15.5976 6.79335 15.207C6.40282 14.8165 6.40282 14.1834 6.79335 13.7928L13.7933 6.79284C14.1839 6.40231 14.817 6.40231 15.2076 6.79284ZM5.3436 9.58575C5.73412 9.97628 5.73412 10.6094 5.3436 11L3.92939 12.4142C2.36729 13.9763 2.36729 16.5089 3.92939 18.071C5.49148 19.6331 8.02414 19.6331 9.58624 18.071L11.0005 16.6568C11.391 16.2663 12.0241 16.2663 12.4147 16.6568C12.8052 17.0473 12.8052 17.6805 12.4147 18.071L11.0005 19.4852C8.65731 21.8284 4.85832 21.8284 2.51517 19.4852C0.172026 17.1421 0.172026 13.3431 2.51517 11L3.92939 9.58575C4.31991 9.19523 4.95307 9.19523 5.3436 9.58575Z"
      clipRule="evenodd"
    />
  </svg>
);

Links.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
};

export default Links;