import React, { useState, useRef, useCallback } from "react";
import { Close } from '../../../Components/icons/General'

const ResizableDraggablePopup = ({ onClose, size, setSize, isOpenArea, children }) => {
  const [position, setPosition] = useState({ x: 0, y: 60 });
  const [isDragging, setIsDragging] = useState(false); // judge whether is dragging the pop position
  // eslint-disable-next-line
  const [isResizing, setIsResizing] = useState(false); //judge whether is resize the width height of pop window
  const dragRef = useRef(null); // found the DOM of dargging position
  const resizeRef = useRef(null); // found the resize button of pop window

  //the function used start dragg pop window position
  const startDrag = useCallback(
    (e) => {
      setIsDragging(true);
      const startX = e.pageX - position.x; // get the start position x
      const startY = e.pageY - position.y; // get the start position y

      // if mouse move out the area, closed dragging and cacluate the new position
      const onMouseMove = (e) => {
        setPosition({
          x: e.pageX - startX,
          y: e.pageY - startY,
        });
      };

      //if mouse in the pop window area, start dragging and removed event listener
      const onMouseUp = () => {
        setIsDragging(false);
        document.removeEventListener("mousemove", onMouseMove);
        document.removeEventListener("mouseup", onMouseUp);
      };

      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
    },
    [position],
  );

  // the function used for resize the width and hight pop window
  const startResize = useCallback(
    (e) => {
      e.stopPropagation(); // stop the dragging propagation

      setIsResizing(true);
      const startX = e.pageX; // get the pop window position x
      const startY = e.pageY; // get the pop window position y
      const startWidth = size.width; // get the start width
      const startHeight = size.height; // get the start height

      // if mouse move out the right down position, closed resize, and caculate the current height and width value
      const onMouseMove = (e) => {
        const newWidth = startWidth + (e.pageX - startX);
        const newHeight = startHeight + (e.pageY - startY);
        setSize({
          width: Math.max(newWidth, 300),
          height: Math.max(newHeight, 200),
        });
      };

      // if mouse move up the right down position, start resize the window
      const onMouseUp = () => {
        setIsResizing(false);
        document.removeEventListener("mousemove", onMouseMove);
        document.removeEventListener("mouseup", onMouseUp);
      };

      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
    },
    [size, setSize],
  );

  // main render area with dragging and resize ==> diaplay the inside children
  return (
    <div className="issueDetailsModal"
      style={{
        width: isOpenArea ? '68%' : '100%',
        transition: 'width 0.5s ease',
      }}
    >
      <div
        className="issueDetailsArea"
        ref={dragRef}
        style={{
          top: `${position.y}px`,
          left: `${position.x}px`,
          width: `${size.width}px`,
          height: size.height === "auto" ? "auto" : `${size.height}px`,
        }}
      >
        <div 
          className="draggableHeader"
          onMouseDown={startDrag}
          style={{
            cursor: isDragging ? "grabbing" : "grab",
          }}
        >
          <Close onClick={onClose} className="closeButton" />
        </div>
        {children}
        <div
          ref={resizeRef}
          style={{
            position: "absolute",
            right: 0,
            bottom: 0,
            width: "20px",
            height: "20px",
            cursor: "se-resize",
            background: "transparent",
          }}
          onMouseDown={startResize}
        />
      </div>
    </div>
  );
};

export default ResizableDraggablePopup;
