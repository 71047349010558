import { useMutation, useQuery, useQueryClient } from "react-query";
import api from "./config";
import useAuthStore from "../stores/useAuthStore";
import { mapIssueFields } from '../config/issueMapping';
import toast from 'react-hot-toast';


// the logIn feature ==> call API 
export const useLoginMutation = () => {
  return useMutation(async (credentials) => {
    const response = await api.post("/token", credentials);
    return response.data;
  });
};

// the all the issue Id ==> call API
export const useAllIssueID = () => {
  return useQuery('allIssueIds', async () => {
    const response = await api.get('/get-all-ids');  
    return response.data;
  });
};

// the search relative issue api and get the key specification area data
export const useSearchMutation = () => {
  return useMutation(async (ticketId) => {
    const token = useAuthStore.getState().token;
    const response = await api.post(
      "search",
      { query: ticketId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  });
};

export const useToggleFavoriteMutation = () => {
  return useMutation(async ({ issueId, sourceId, isFavorite }) => {
    const token = useAuthStore.getState().token;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    if (isFavorite) {
      const response = await api.delete("/favorites", {
        ...config,
        data: { issue_id: issueId, source_id: sourceId },
      });
      return response.data;
    } else {
      const response = await api.post(
        "/favorites",
        { issue_id: issueId, source_id: sourceId },
        config,
      );
      return response.data;
    }
  });
};

// the update password api function
export const useUpdatePasswordMutation = () => {
  return useMutation(async (passwordData) => {
    const token = useAuthStore.getState().token;
    const response = await api.post("/update-password",
      {
        current_password: passwordData.currentPassword,
        password: passwordData.newPassword,
        password_confirmation: passwordData.confirmPassword
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  });
};

export const useShareIssuesMutation = () => {
  return useMutation(async ({ emails, shareUrl }) => {
    if (!emails?.length || !shareUrl) {
      throw new Error('Invalid share parameters');
    }
    try {
      const token = useAuthStore.getState().token;
      
      const response = await api.post(
        "/share",
        { 
          emails: Array.isArray(emails) ? emails : [emails],
          share_url: shareUrl
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data?.redirect_url) {
        window.location.href = response.data.redirect_url;
        return;
      }
      
      return response.data;
      
    } catch (error) {
        console.error('Share error:', error);
      throw error;
    }
  });
};

export const useUpdateIssueMutation = () => {
  return useMutation(async ({ issueId, field, value }) => {
    const token = useAuthStore.getState().token;
    const response = await api.post(
      "/update-issue",
      { 
        issue_id: issueId,
        field: field,
        value: value 
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  });
};

// Hook for fetching saved issues
export const useSavedIssuesQuery = () => {
  return useQuery(
    'savedIssues',
    async () => {
      const token = useAuthStore.getState().token;
      const savedResponse = await api.get("/saves", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!savedResponse.data?.saves || savedResponse.data.saves.length === 0) {
        return [];
      }

      const issueIds = savedResponse.data.saves.map((save) => save.issue_id).join(',');

      const issuesResponse = await api.get(`/search/issues?issuelist=${issueIds}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return issuesResponse.data.data.map((issue) => mapIssueFields(issue));
    },
    {
      staleTime: Infinity, // Data is always fresh
      cacheTime: Infinity, // Cache data indefinitely
      refetchOnMount: true, // Refetch on component mount
      refetchOnWindowFocus: false, // Do not refetch on window focus
    }
  );
};

// Hook for saving issues
export const useSaveIssuesMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ issueIds, sourceId }) => {
      const token = useAuthStore.getState().token;
      const response = await api.post(
        "/saves",
        { 
          issue_ids: issueIds,
          source_id: sourceId 
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries('savedIssues');
        toast.success(
          `${data.saved_count} issues saved successfully${data.duplicate_count ? ` (${data.duplicate_count} duplicates)` : ''}`
        );
      },
      onError: () => {
        toast.error('Failed to save issues');
      },
    }
  );
};

// Hook for deleting saved issues
export const useDeleteSavedIssuesMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (issueIds) => {
      const token = useAuthStore.getState().token;
      const response = await api.delete("/saves", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: { issue_ids: issueIds }
      });
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('savedIssues');
        toast.success('Issues removed from saved successfully');
      },
      onError: () => {
        toast.error('Failed to remove issues from saved');
      },
    }
  );
};

// Hook for fetching hidden issues
export const useHiddenIssuesQuery = () => {
  return useQuery(
    'hiddenIssues',
    async () => {
      const token = useAuthStore.getState().token;
      const hiddenResponse = await api.get("/hides", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!hiddenResponse.data?.hides || hiddenResponse.data.hides.length === 0) {
        return [];
      }

      const issueIds = hiddenResponse.data.hides.map((hide) => hide.issue_id).join(',');

      const issuesResponse = await api.get(`/search/issues?issuelist=${issueIds}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return issuesResponse.data.data.map((issue) => mapIssueFields(issue));
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );
};

// Hook for hiding issues
export const useHideIssuesMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ issueIds, sourceId }) => {
      const token = useAuthStore.getState().token;
      const response = await api.post(
        "/hides",
        { 
          issue_ids: issueIds,
          source_id: sourceId 
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries('hiddenIssues');
        toast.success(
          `${data.hidden_count} issues hidden successfully${data.duplicate_count ? ` (${data.duplicate_count} duplicates)` : ''}`
        );
      },
      onError: () => {
        toast.error('Failed to hide issues');
      },
    }
  );
};

// Hook for unhiding issues
export const useUnhideIssuesMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (issueIds) => {
      const token = useAuthStore.getState().token;
      const response = await api.delete("/hides", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: { issue_ids: issueIds }
      });
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('hiddenIssues');
        toast.success('Issues unhidden successfully');
      },
      onError: () => {
        toast.error('Failed to unhide issues');
      },
    }
  );
};