// the default empty state key specification area before user searched some issue id
import React from "react";
import PropTypes from "prop-types";
import './DefaultComp.css';

const DefaultComp = ({
    title,
    context,
    Icon
}) => {

    return <div className="defaultArea">

        <div className="iconWithText">
            <div className="svgIcon">
                {Icon && <Icon size={45} color="var(--brand-secondary-500)" />}
            </div>

            <span className="titleStyle">
                {title}
            </span>

            <span className="contextStyle">
                {context}
            </span>
        </div>
    </div>
}

DefaultComp.propTypes = {
    title: PropTypes.string.isRequired,
    context: PropTypes.string.isRequired,
    Icon: PropTypes.elementType,
}

export default DefaultComp;
